


































































































import { Component, Prop } from 'vue-property-decorator';
import { getComponent, getConfigEnv, jsonParse } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
// import { DateTime } from 'luxon';
// import operationsModule from '@/store/modules/operationsModule';
// import setpointModule from '@/store/modules/setpointModule';
// import setpointV2Module from '@/store/modules/setpointV2Module';
import AWS from 'aws-sdk';
import {
  // POST_SHAPEFILE_LAMBDA_NAME,
  POST_SHAPEFILE_LAMBDA_REGION,
  IDENTITY_POOL_REGION,
  IDENTITY_POOL_ID,
  // SHAPEFILE_BUCKET_NAME,
  // SHAPEFILE_BUCKET_REGION,
  // MAPBOX_ACCESS_TOKEN,
} from '@/lib/constants';
import DataLoading from '../../lib/mixins/dataLoading';
import TasqJob from '../../interfaces/tasqs/TasqJob';

@Component({
  components: {
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    LineChart: () => import('@/lib/charts/lineChart'),
  },
})
export default class SetpointFeedbackV3 extends mixins(DataLoading) {
  @Prop({ required: true }) tasq!: TasqJob;

	isLoadingPlot = false

  showTpLpPopup = false;

  showTpPopup = false;

  download(filename, text) {
	  const element = document.createElement('a');
	  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
	  element.setAttribute('download', filename);
	  element.style.display = 'none';
	  document.body.appendChild(element);
	  element.click();
	  document.body.removeChild(element);
  }

  downloadChart() {
	  this.isLoadingPlot = true;
	  AWS.config.update({
	    region: IDENTITY_POOL_REGION,
	    credentials: new AWS.CognitoIdentityCredentials({
	      IdentityPoolId: IDENTITY_POOL_ID,
	    }),
	  });
	  const lambda = new AWS.Lambda({
	    region: POST_SHAPEFILE_LAMBDA_REGION,
	    apiVersion: '2015-03-31',
	  });


    console.log(JSON.stringify({
	      // @ts-ignore
	      Operator: getConfigEnv('OPERATOR_LOWERCASED'),
	      // @ts-ignore
	      NodeID: this.tasq.wellName,
	      DecidedBy: this.tasq.setpointRecord.decided_by,

	    }))

      console.log(`tasq-workflow-${getConfigEnv('STAGE')}-GetFullPlotFileSyncSource`)

	  const pullParams = {
	    FunctionName: `tasq-workflow-${getConfigEnv('STAGE')}-GetFullPlotFileSyncSource`,
	    InvocationType: 'RequestResponse', // Event | RequestResponse | DryRun
	    LogType: 'None',
	    Payload: JSON.stringify({
	      // @ts-ignore
	      Operator: getConfigEnv('OPERATOR_LOWERCASED'),
	      // @ts-ignore
	      NodeID: this.tasq.wellName,
	      DecidedBy: this.tasq.setpointRecord.decided_by,

	    }),
	  };
	  lambda.invoke(pullParams, (err, data) => {
	    if (err) {
	      console.log(err);
	    } else {
	      // @ts-ignore
	      const parsed_payload = JSON.parse(data.Payload);
	      const parsed_html = JSON.parse(parsed_payload.Results);
	      this.download(`setpoint_rec_${this.tasq.wellName}.html`, parsed_html.data);
	      this.isLoadingPlot = false;
	    }
	  });
  }
}
