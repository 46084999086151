import { render, staticRenderFns } from "./SetpointFeedbackInfoV3.vue?vue&type=template&id=dca4ac26&scoped=true&"
import script from "./SetpointFeedbackInfoV3.vue?vue&type=script&lang=ts&"
export * from "./SetpointFeedbackInfoV3.vue?vue&type=script&lang=ts&"
import style0 from "./SetpointFeedbackInfoV3.vue?vue&type=style&index=0&id=dca4ac26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dca4ac26",
  null
  
)

export default component.exports